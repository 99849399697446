<template>
  <div>
    <select-type
      v-if="headers"
      v-model="value['header']"
      class="col-sm-4 col-md-3"
      name="header"
      :identifier="'header-selection'"
      :label="'Nagłówek'"
      :required="false"
      :options="headers"
      :disabled="!headers"
      :empty-first="true"
    />
    <select-type
      v-if="roles"
      v-model="value['role']"
      class="col-sm-4 col-md-3"
      name="role"
      :identifier="'role-selection'"
      :label="'Rola'"
      :required="false"
      :options="roles"
      :disabled="!roles"
      :empty-first="true"
    />
  </div>
</template>

<script>
import SelectType from '../../../../share/form/type/SelectType'
export default {
  name: 'HeaderRoleVisibilityType',
  components: { SelectType },
  props: {
    value: {},
    headers: {},
    roles: {}
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
