<template>
  <div class="calendar--business-hours">
    <section
      v-if="allowedAccess"
      class="content box-management"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body">
              <form>
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Ustawienia widoczności nagłówków</h2>
                    <list
                      v-if="headerRolesLoaded"
                      :items="model.headerRoles"
                      :empty-element="createEmptyElement()"
                      @modified="forceToSave"
                    >
                      <template
                        slot="element"
                        slot-scope="listItems"
                      >
                        <header-role-visibility-type
                          v-model="listItems.item"
                          :headers="headers"
                          :roles="roles"
                        />
                      </template>
                    </list>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <save-data-information
      :show="saveDataInformationVisible"
      @close="saveDataInformationVisible = false"
    />
    <login-modal
      :show="loginVisible"
      :back-url-name="`${service}_header-visibility`"
      @close="loginVisible = false"
      @success="init()"
    />
  </div>
</template>

<script>
import Page from '../../../share/content/Page'
import api from '../../../../api'
import List from '../../../share/form/List'
import LoginModal from '../modal/LoginModal'
import SaveDataInformation from '../modal/SaveDataInformation'
import HeaderRoleVisibilityType from '../form/type/HeaderRoleVisibilityType'

export default {
  components: {
    HeaderRoleVisibilityType,
    List,
    LoginModal,
    SaveDataInformation
  },
  mixins: [
    Page
  ],
  data () {
    return {
      events: null,
      rolesLoaded: false,
      roles: [],
      headers: [],
      headerTitle: { title: 'Ustawienia online', description: 'Tryb edycji' },
      loginVisible: false,
      allowedAccess: false,
      model: {
        headerRoles: []
      },
      headerRolesLoaded: false,
      service: this.$route.meta.acl.service,
      isSaved: true,
      saveDataInformationVisible: false,
      previouslySelectedRole: null
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.loadRoles(this.service)
    this.loadData(this.service)
    this.$nextTick(() => {
      this.loginVisible = true
      this.$events.on(this.events.headerRolesSubmit, this.submitData)
    })
  },
  methods: {
    getInitEvents () {
      this.events = {
        headerRolesSubmit: `${this.service}:headersSubmit`
      }
    },
    init () {
      this.allowedAccess = true
      this.loadData()
    },
    createEmptyElement () {
      return {
        id: null,
        role: null,
        visibility: 1,
        header: null
      }
    },
    loadData () {
      this.loadHeaders(this.service)
      this.loadHeadersVisibilities(this.service)
    },
    loadRoles (contractor) {
      this.roles = []
      api.request(contractor, 'get', '/roles')
        .then((response) => {
          const roles = response.data
          for (let c = 0; c < roles.length; ++c) {
            this.roles.push({ label: roles[c].label, value: roles[c].id })
          }
          this.rolesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról'
          })
        })
    },
    loadHeaders (contractor) {
      this.headers = []
      api.request(contractor, 'get', `/headers`)
        .then((response) => {
          const headers = response.data
          for (let c = 0; c < headers.length; ++c) {
            this.headers.push({ label: headers[c].label, value: headers[c].id })
          }
          this.headersLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować nagłówków'
          })
        })
    },
    forceToSave () {
      this.isSaved = false
    },
    loadHeadersVisibilities (contractor) {
      api.request(contractor, 'get', `/headers/visibility/all`)
        .then((response) => {
          this.model.headerRoles = response.data.map(headerRole => {
            return {
              id: headerRole.id,
              visibility: headerRole.visibility,
              role: headerRole.role.id,
              header: headerRole.header.id
            }
          })

          this.headerRolesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ustawień online'
          })
        })
    },
    submitData () {
      let submitData = {
        headerVisibilities: []
      }
      for (let i = 0; i < this.model.headerRoles.length; ++i) {
        submitData.headerVisibilities.push({
          id: this.model.headerRoles[i].id,
          role: this.model.headerRoles[i].role,
          header: this.model.headerRoles[i].header,
          visibility: this.model.headerRoles[i].visibility
        })
      }
      api.request(this.service, 'put', '/headers/visibility', submitData)
        .then(() => {
          this.isSaved = true
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
          this.loadData(this.service, this.model.roleId)
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: `Błąd zapisu danych: ${error.message}`
          })
        })
    }
  }
}
</script>

<style scoped>
    h2 {
        font-size: 18px
    }
</style>
